import React, { useState } from 'react';
import { JobHeaderTextContent } from './JobHeaderTextContent';
import { Action } from '@reverse-hr/pattern-library';
import { Trans, useTranslation } from 'react-i18next';
import { JobHeaderActionMenu } from './JobHeaderActionMenu';
import { JobHeaderModal } from './JobHeaderModal';
import { JobHeaderPresentationMeetingAlert } from './JobHeaderPresentationMeetingAlert';
import { EMPLOYER_VIEW_URL } from '../../.configs/environment';

export const JobHeader = ({
  job,
  suitableCandidates,
  onFreezeJobClick,
  onAssignJobClick,
  onRefreshCandidates,
}) => {
  const { t } = useTranslation();

  const [isShareEmployerViewModalOpen, setIsShareEmployerViewModalOpen] =
    useState(false);

  const [
    isSendSuitableCandidatesModalOpen,
    setIsSendSuitableCandidatesModalOpen,
  ] = useState(false);

  const [isPresentationMeetingModalOpen, setIsPresentationMeetingModalOpen] =
    useState(false);

  const [isProfilingMeetingModalOpen, setIsProfilingMeetingModalOpen] =
    useState(false);

  const onShareEmployerViewClick = () => setIsShareEmployerViewModalOpen(true);
  const onShareEmployerViewClose = () => setIsShareEmployerViewModalOpen(false);

  const onSendCandidatesClick = () =>
    setIsSendSuitableCandidatesModalOpen(true);

  const onSetPresentationMeetingDateClick = () =>
    setIsPresentationMeetingModalOpen(true);

  const onSetProfilingMeetingDateClick = () =>
    setIsProfilingMeetingModalOpen(true);

  const onCloseSendSuitableCandidatesModalClick = () =>
    setIsSendSuitableCandidatesModalOpen(false);

  const onCloseMeetingDateModalClick = () => {
    setIsPresentationMeetingModalOpen(false);
    setIsProfilingMeetingModalOpen(false);
  };

  const hasNewCandidates = suitableCandidates.length > 0;

  const notifyCandidatesLabel = hasNewCandidates ? (
    <Trans
      i18nKey="job.header.notifyClientNewCandidates"
      count={suitableCandidates.length}
    >
      0<span className="c-job-header__action-count">1</span>
    </Trans>
  ) : (
    t('job.header.noNewCandidates')
  );

  return (
    <div className="c-job-header">
      <div className="c-job-header__main-content">
        <JobHeaderTextContent
          closedOnDate={job.closed_at}
          companyName={job.customer.company_name}
          jobCode={job.code}
          jobReverseUrl={job.reverse_job_url}
          jobTitle={job.title}
          publishedOnDate={job.publish_date}
          successFee={job.success_fee}
        />

        <div className="c-job-header__other-actions">
          <Action
            type="simple-text"
            onClick={onShareEmployerViewClick}
            label={t('job.header.shareEmployerView')}
            icon="icn-share-outline-24px"
            iconPosition="right"
            modifier="c-job-header__action c-job-header__action--share-employer-view"
          />

          <Action
            type="simple-text"
            onClick={onSendCandidatesClick}
            label={notifyCandidatesLabel}
            icon="icn-mail-send-24px"
            iconPosition="right"
            disabled={!hasNewCandidates}
            modifier="c-job-header__action c-job-header__action--send-candidates"
          />

          <JobHeaderActionMenu
            closeJobUrl={job.reverse_job_close_url}
            isJobFrozen={job.frozen}
            isPresentationMeetingDefined={!!job.presentationMeetingScheduledAt}
            isProfilingMeetingDefined={!!job.profilingMeetingScheduledAt}
            onFreezeJobClick={onFreezeJobClick}
            onAssignJobClick={onAssignJobClick}
            onSetPresentationMeetingDateClick={
              onSetPresentationMeetingDateClick
            }
            onSetProfilingMeetingDateClick={onSetProfilingMeetingDateClick}
            employerViewJobUrl={`${EMPLOYER_VIEW_URL}/jobs/${job.uuid}`}
            houndJobUrl={job.hound_list_url}
            miroUrl={job.miro_url}
            reverseJobUrl={job.reverse_job_url}
          />
        </div>

        <JobHeaderModal
          isShareEmployerViewModalOpen={isShareEmployerViewModalOpen}
          isSendSuitableCandidatesModalOpen={isSendSuitableCandidatesModalOpen}
          isPresentationMeetingModalOpen={isPresentationMeetingModalOpen}
          isProfilingMeetingModalOpen={isProfilingMeetingModalOpen}
          job={job}
          suitableCandidates={suitableCandidates}
          onShareEmployerViewClose={onShareEmployerViewClose}
          onCloseMeetingDateModalClick={onCloseMeetingDateModalClick}
          onCloseSendSuitableCandidatesModalClick={
            onCloseSendSuitableCandidatesModalClick
          }
          onSuitableCandidatesSent={onRefreshCandidates}
        />
      </div>

      <JobHeaderPresentationMeetingAlert
        job={job}
        onSetPresentationMeetingDateClick={onSetPresentationMeetingDateClick}
      />
    </div>
  );
};
