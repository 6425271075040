import type { FC } from 'react';
import React, { useContext } from 'react';
import { TaskModalBody, TaskModalContext } from '../../TaskModal';
import { ShareEmployerView } from '../../ShareEmployerView';

export const TaskShareEmployerView: FC = () => {
  const { jobLegacy } = useContext(TaskModalContext);

  return jobLegacy ? (
    <>
      <TaskModalBody className="c-task-share-employer-view">
        <ShareEmployerView jobUuid={jobLegacy.uuid} />
      </TaskModalBody>
    </>
  ) : null;
};
