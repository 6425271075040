import type { TJwt } from '../models/User';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { apiJwtPost, apiJwtRefreshPost } from '../api/user';
import { LOGIN_URL } from '../.configs/environment';

export interface TUtilityRequestWithAuthPayload {
  endpoint: string;
  body?: unknown;
  headers?: Record<string, string>;
  method?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';
  query?:
    | string
    | string[][]
    | Record<string, string | string[] | undefined | number>;
}

export const utilityRequestWithAuth = async <T>({
  endpoint,
  body,
  headers,
  method,
  query,
}: TUtilityRequestWithAuthPayload): Promise<T | void> => {
  try {
    await utilityCheckTokensStatus();
  } catch (error) {
    utilityClearLocalStorageTokens();
    return window.location.assign(LOGIN_URL);
  }

  try {
    const token = localStorage.getItem('token');
    const sanitizedQuery = getSanitizedQuery(query);

    const queryParams = sanitizedQuery
      ? `?${new URLSearchParams(sanitizedQuery).toString()}`
      : '';

    const response = await fetch(`${endpoint}${queryParams}`, {
      method: method ?? 'GET',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        ...headers,
      },
    });

    if (response.status === 204) {
      return;
    }

    if (response.status === 401) {
      utilityClearLocalStorageTokens();
    }

    const responseBody = await response.json();

    return response.ok ? responseBody : Promise.reject(responseBody);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const utilityCheckTokensStatus = async () => {
  const { token, refreshToken, isTokenExpired } = getLocalStorageTokensStatus();

  try {
    if (token === null || refreshToken === null) {
      const fetchedTokens = await apiJwtPost();
      return utilitySetLocalStorageTokens(fetchedTokens);
    }

    if (isTokenExpired) {
      const refreshedTokens = await apiJwtRefreshPost(refreshToken);
      return utilitySetLocalStorageTokens(refreshedTokens);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const utilitySetLocalStorageTokens = ({
  token,
  refreshToken,
}: Partial<TJwt>) => {
  if (token === undefined || refreshToken === undefined) {
    return utilityClearLocalStorageTokens();
  }

  localStorage.setItem('token', token);
  localStorage.setItem('refreshToken', refreshToken);
};

export const utilityClearLocalStorageTokens = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
};

const getLocalStorageTokensStatus = () => {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');

  if (token === null || refreshToken === null) {
    utilityClearLocalStorageTokens();

    return {
      token: null,
      refreshToken: null,
      isTokenExpired: false,
    };
  }

  const decodedToken = jwtDecode<JwtPayload>(token);
  const now = new Date();

  const isTokenExpired =
    decodedToken.exp !== undefined &&
    decodedToken.exp !== null &&
    Math.round(now.getTime() / 1000) >= decodedToken.exp;

  return {
    token,
    refreshToken,
    isTokenExpired,
  };
};

const getSanitizedQuery = (
  queryParams: TUtilityRequestWithAuthPayload['query'],
): string | string[][] | Record<string, string> | undefined => {
  if (
    typeof queryParams === 'string' ||
    queryParams === undefined ||
    Array.isArray(queryParams)
  ) {
    return queryParams;
  }

  return Object.fromEntries(
    Object.entries(queryParams)
      .filter(([, value]) => value !== undefined)
      .map(([key, value]) => [key, value!.toString()]),
  );
};
