const {
  REACT_APP_JARVIS_ENVIRONMENT,
  REACT_APP_API_ENVIRONMENT,
  REACT_APP_JARVIS_PORT,
  REACT_APP_API_PORT,
} = process.env;

const ENVIRONMENTS = {
  DEVELOPMENT: 'development',
  LOCAL: 'local',
  STAGING: 'staging',
  PRODUCTION: 'production',
};

const JARVIS_ENVIRONMENT =
  REACT_APP_JARVIS_ENVIRONMENT ?? ENVIRONMENTS.DEVELOPMENT;

const API_ENVIRONMENT = REACT_APP_API_ENVIRONMENT ?? ENVIRONMENTS.DEVELOPMENT;
const JARVIS_PORT = REACT_APP_JARVIS_PORT ?? 3000;
const API_PORT = REACT_APP_API_PORT ?? 9000;

const JARVIS_ENV_TO_HOST_MAP = {
  [ENVIRONMENTS.DEVELOPMENT]: `http://localhost:${JARVIS_PORT}`,
  [ENVIRONMENTS.LOCAL]: 'http://localhost',
  [ENVIRONMENTS.STAGING]: 'https://staging.app.reverse.hr',
  [ENVIRONMENTS.PRODUCTION]: 'https://app.reverse.hr',
};

const API_ENV_TO_HOST_MAP = {
  ...JARVIS_ENV_TO_HOST_MAP,
  [ENVIRONMENTS.DEVELOPMENT]: `http://localhost:${API_PORT}`,
};

const API_ENV_TO_EXTERNAL_LINKS_HOST_MAP = {
  ...API_ENV_TO_HOST_MAP,
  [ENVIRONMENTS.DEVELOPMENT]: JARVIS_ENV_TO_HOST_MAP[ENVIRONMENTS.LOCAL],
};

const API_HOST = API_ENV_TO_HOST_MAP[API_ENVIRONMENT];

const EXTERNAL_LINKS_HOST =
  API_ENV_TO_EXTERNAL_LINKS_HOST_MAP[JARVIS_ENVIRONMENT];

const EMPLOYER_VIEW_URL = `${EXTERNAL_LINKS_HOST}/employer_view`;
const JARVIS_HOST = JARVIS_ENV_TO_HOST_MAP[JARVIS_ENVIRONMENT];
const LOGIN_URL = `${EXTERNAL_LINKS_HOST}/login`;

module.exports = {
  API_HOST,
  EMPLOYER_VIEW_URL,
  ENVIRONMENTS,
  EXTERNAL_LINKS_HOST,
  JARVIS_HOST,
  LOGIN_URL,
};
