import React from 'react';
import isEqual from 'lodash.isequal';
import { useTranslation } from 'react-i18next';
import { useJobStatistics } from '../hooks/use-job-statistics';

const JobNumbers = ({
  modifier,
  uuid,
  publishDate,
  presentationMeetingDate,
  statistics,
}) => {
  const { t } = useTranslation();

  const mappedNumbers = useJobStatistics(
    uuid,
    publishDate,
    presentationMeetingDate,
    statistics,
  );

  return (
    <div className={`c-job-numbers ${modifier}`}>
      {!!mappedNumbers.days
        ? Object.keys(mappedNumbers).map((type, i) => {
            if (type === 'days') {
              return (
                <div
                  key={`${type}-${i}`}
                  className="c-job-numbers__cell c-job-numbers__cell--days"
                  data-testid="job-numbers-days"
                >
                  <div className="c-job-numbers__item">
                    <p className="c-job-numbers__label">
                      {t('job.numbers.days')}
                    </p>
                    <p className="c-job-numbers__value">
                      <span
                        data-testid="job-numbers-days-value"
                        className={`nmbrs ${
                          mappedNumbers[type].values[type].alarm
                            ? 'nmbrs--alert'
                            : ''
                        }`}
                      >{`${mappedNumbers[type].values[type].value}`}</span>
                    </p>
                  </div>
                </div>
              );
            }
            if (type.includes('row')) {
              const secondRow = type === 'row-two';
              return (
                <div
                  key={`${type}-${i}`}
                  className={`c-job-numbers__cell c-job-numbers__cell--${type}`}
                  data-testid="job-numbers-row"
                >
                  {Object.keys(mappedNumbers[type]).map((item, index) => {
                    const values = mappedNumbers[type][item].values;
                    const value = Object.keys(values).map(key => (
                      <span
                        key={key}
                        data-testid={key}
                        aria-label={t(
                          'job.numbers.' +
                            item +
                            (key === item ? '' : '_part.' + key),
                        )}
                        data-balloon-pos={secondRow ? 'up' : 'down'}
                        data-balloon-length="medium"
                        className={`nmbrs ${
                          values[key].alarm ? 'nmbrs--alert' : ''
                        }`}
                      >
                        {values[key].url ? (
                          <a
                            href={`${values[key].url}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >{`${values[key].value}`}</a>
                        ) : (
                          values[key].value
                        )}
                      </span>
                    ));
                    return (
                      <div
                        key={`${item}-${index}`}
                        className={`c-job-numbers__item ${
                          secondRow &&
                          index < Object.keys(mappedNumbers[type]).length - 2
                            ? 'c-job-numbers__item--skewed-border'
                            : ''
                        }`}
                      >
                        <p className="c-job-numbers__label">
                          {t('job.numbers.' + item)}
                        </p>
                        <p
                          className="c-job-numbers__value"
                          data-testid={`job-numbers-${item}-value`}
                        >
                          {value}
                        </p>
                      </div>
                    );
                  })}
                </div>
              );
            }
            return null;
          })
        : null}
    </div>
  );
};

JobNumbers.defaultProps = {
  modifier: '',
  numbers: {},
};

const MemoizedJobNumbers = React.memo(JobNumbers, (props, nextProps) => {
  if (isEqual(props.statistics, nextProps.statistics)) {
    return true;
  }
});

export default MemoizedJobNumbers;
