import { JARVIS_HOST } from '../.configs/environment';
import type { TJwt } from '../models/User';

export const apiJwtPost = async (): Promise<TJwt> => {
  const response = await fetch(`${JARVIS_HOST}/api/v1/users/jwt`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    return Promise.reject(response);
  }

  return await response.json();
};

export const apiJwtRefreshPost = async (
  refreshToken: TJwt['refreshToken'],
): Promise<TJwt> => {
  const response = await fetch(`${JARVIS_HOST}/api/v1/users/token/refresh`, {
    method: 'POST',
    body: JSON.stringify({ refreshToken }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    return Promise.reject(response);
  }

  return await response.json();
};
