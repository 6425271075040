import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkWrapper } from '@reverse-hr/pattern-library';
import { OfferDetailsFormContext } from './OfferDetailsForm';

export const OfferDetailsFormContract = () => {
  const { t } = useTranslation(null, {
    keyPrefix: 'offerDetails.contractValues',
  });

  const { job } = useContext(OfferDetailsFormContext);

  return (
    <div className="c-offer-details-form__section c-offer-details-form-contract">
      <h3 className="c-offer-details-form__box-title">{t('title')}</h3>

      <div className="c-offer-details-form-contract__field">
        <p className="c-offer-details-form-contract__minimal-label">
          {t('minimal', {
            amount: job.successFeeMinimumValue,
            currency: job.currencySymbol,
          })}
        </p>

        <LinkWrapper
          external
          icon="icn-external-link-24px"
          iconPosition="right"
          label={t('contractLink')}
          target="_blank"
          type="simple-text"
          url={`https://app.reverse.hr/dashboard/recruitment/contracts/${job.contractId}`}
        />
      </div>
    </div>
  );
};
