import { Action } from '@reverse-hr/pattern-library';
import React, { forwardRef, useState } from 'react';
import { ShareEmployerView } from '../ShareEmployerView';
import { useStoreState } from 'easy-peasy';
import { selectorJobState } from '../../selectors/job/selectors';
import type { TJobLegacy } from '../../models/Job';

interface TShareEmployerViewModalProps {
  onClose: () => void;
}

export const ShareEmployerViewModal = forwardRef(
  ({ onClose }: TShareEmployerViewModalProps, _ref) => {
    const [isRemoveRecipientModalOpen, setIsRemoveRecipientModalOpen] =
      useState<boolean>(false);

    const { job } = useStoreState(selectorJobState) as { job: TJobLegacy };

    const onRemoveRecipientModalChange = (isOpen: boolean) =>
      setIsRemoveRecipientModalOpen(isOpen);

    return (
      <div className="c-share-employer-view-modal">
        {isRemoveRecipientModalOpen ? null : (
          <header className="c-share-employer-view-modal__header">
            <Action
              onClick={onClose}
              icon="icn-close-24px"
              type="raw"
              iconOnly
            />
          </header>
        )}

        <ShareEmployerView
          jobUuid={job.uuid}
          onRemoveRecipientModalChange={onRemoveRecipientModalChange}
        />
      </div>
    );
  },
);
