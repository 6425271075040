import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { CLOSING_OFFER_DETAIL_KEY_LIST } from '../../constants/applications';

export const useFormValidation = ({ offer, onChange }) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'offerDetails.validationErrors',
  });

  const [formData, setFormData] = useState({
    isSalaryTypeAnnual: !offer.numberOfSalaryFractions,
    isAccommodationActive: !!offer.accommodationMonths || false,
    isGeneralBonusActive:
      !!offer.bonusDescription ||
      (offer.bonusAmount !== null && offer.bonusAmount !== undefined),
    madeOn: offer.madeOn || format(new Date(), 'yyyy-MM-dd'),
    commitmentLetter: offer.commitmentLetter ?? undefined,
    resignationLetter: offer.resignationLetter ?? undefined,
    salaryValue: offer.salaryValue ?? '',
    numberOfSalaryFractions: offer.numberOfSalaryFractions ?? '',
    successFeePercentageOfCompensationPackage:
      offer.successFeePercentageOfCompensationPackage ?? '',
    isSuccessFeeRecapVisible: offer.isSuccessFeeRecapVisible ?? true,
    alreadyInvoicedRetainerFee: offer.alreadyInvoicedRetainerFee ?? '',
    alreadyInvoicedConfidenceFee: offer.alreadyInvoicedConfidenceFee ?? '',
    discountPercentage: offer.discountPercentage ?? '',
    includesCompanyCar: offer.includesCompanyCar ?? false,
    includesHealthInsurance: offer.includesHealthInsurance ?? false,
    includesStocks: offer.includesStocks ?? false,
    accommodationMonths: offer.accommodationMonths || '',
    bonusDescription: offer.bonusDescription ?? '',
    bonusAmount: offer.bonusAmount ?? '',
  });

  const [formErrors, setFormErrors] = useState(null);

  const getNumberFieldValidation = (
    schema,
    {
      isRequired = false,
      isPercentage = false,
      isNatural = false,
      isNumberValue = false,
    } = {},
  ) =>
    schema
      .transform(value => {
        if (value === null) {
          return '';
        }

        const convertedValue = Number(value);

        if (isNaN(convertedValue) || value === '') {
          return value;
        }

        return isNumberValue ? convertedValue : value;
      })
      .test('salaryValueValidation', (value, { createError }) => {
        if (value === '' || value === null) {
          return isRequired ? createError({ message: ' ' }) : true;
        }

        const convertedValue = Number(value);

        if (isNaN(convertedValue)) {
          return createError({ message: t('valueType') });
        }

        const minValue = isNatural ? 1 : 0;

        if (convertedValue < minValue) {
          return createError({ message: t('positive') });
        }

        if (isPercentage && convertedValue > 100) {
          return createError({ message: t('percentage') });
        }

        return true;
      });

  const documentValidation = yup
    .mixed()
    .nullable()
    .transform(value => {
      if (!value || typeof value !== 'string') {
        return undefined;
      }

      return value;
    });

  const validationSchema = yup.object({
    isSalaryTypeAnnual: yup.boolean(),
    isAccommodationActive: yup.boolean(),
    isGeneralBonusActive: yup.boolean(),
    madeOn: yup.string().required(t('required')),
    commitmentLetter: documentValidation,
    resignationLetter: documentValidation,
    salaryValue: getNumberFieldValidation(yup.mixed(), {
      isRequired: true,
      isNatural: true,
    }),
    numberOfSalaryFractions: yup
      .mixed()
      .nullable()
      .when('isSalaryTypeAnnual', {
        is: false,
        then: schema =>
          getNumberFieldValidation(schema, {
            isRequired: true,
            isNatural: true,
            isNumberValue: true,
          }),
        otherwise: schema => schema.nullable().transform(() => null),
      }),
    successFeePercentageOfCompensationPackage: getNumberFieldValidation(
      yup.mixed(),
      {
        isRequired: true,
        isPercentage: true,
        isNatural: true,
      },
    ),
    alreadyInvoicedRetainerFee: getNumberFieldValidation(yup.mixed()),
    alreadyInvoicedConfidenceFee: getNumberFieldValidation(yup.mixed()),
    discountPercentage: getNumberFieldValidation(yup.mixed(), {
      isPercentage: true,
    }),
    isSuccessFeeRecapVisible: yup.boolean(),
    accommodationMonths: yup
      .mixed()
      .nullable()
      .when('isAccommodationActive', {
        is: true,
        then: schema =>
          getNumberFieldValidation(schema, {
            isRequired: true,
            isNatural: true,
            isNumberValue: true,
          }),
        otherwise: schema => schema.transform(() => null),
      }),
    bonusAmount: yup
      .mixed()
      .nullable()
      .when('isGeneralBonusActive', {
        is: true,
        then: schema =>
          getNumberFieldValidation(schema, { isNatural: true }).transform(
            value => value || null,
          ),
        otherwise: schema => schema.transform(() => null),
      }),
    bonusDescription: yup
      .string()
      .nullable()
      .when('isGeneralBonusActive', {
        is: true,
        then: schema => schema.required(' '),
        otherwise: schema => schema.transform(() => null),
      }),
  });

  const validateForm = async updatedFormData => {
    try {
      const parsedFormData = await validationSchema.validate(updatedFormData, {
        abortEarly: false,
      });

      setFormErrors(null);
      return { parsedFormData, isValid: true };
    } catch (error) {
      const errorStack = error.inner.reduce(
        (outputErrorStack, innerError) => ({
          ...outputErrorStack,
          [innerError.path]: innerError.message,
        }),
        {},
      );

      setFormErrors(errorStack);
      return { parsedFormData: error.value, isValid: false };
    }
  };

  const onFormChange = async updatedFormData => {
    const { parsedFormData, isValid } = await validateForm({
      ...formData,
      ...updatedFormData,
    });

    setFormData(parsedFormData);

    onChange({
      formData: getSaveOfferPayloadData(parsedFormData),
      isValid,
    });
  };

  useEffect(() => {
    onFormChange(formData);
  }, []);

  return { formData, formErrors, onFormChange };
};

const getSaveOfferPayloadData = formData => {
  const payloadData = Object.keys(formData).reduce(
    (outputOfferPayload, fieldKey) => {
      if (CLOSING_OFFER_DETAIL_KEY_LIST.includes(fieldKey)) {
        outputOfferPayload[fieldKey] = formData[fieldKey];
      }

      return outputOfferPayload;
    },
    {},
  );

  return {
    ...payloadData,
    alreadyInvoicedConfidenceFee:
      payloadData.alreadyInvoicedConfidenceFee === ''
        ? '0'
        : payloadData.alreadyInvoicedConfidenceFee,
    alreadyInvoicedRetainerFee:
      payloadData.alreadyInvoicedRetainerFee === ''
        ? '0'
        : payloadData.alreadyInvoicedRetainerFee,
    discountPercentage:
      payloadData.discountPercentage === ''
        ? '0'
        : payloadData.discountPercentage,
  };
};
