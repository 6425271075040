import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { formatDate } from '../helpers/utils';
import { Parser } from 'html-to-react';

import JobCompletion from './JobCompletion';
import classNames from 'classnames';
import { Action, Avatar } from '@reverse-hr/pattern-library';

const JobSummary = ({
  uuid,
  infos,
  statistics,
  steps,
  horserace,
  frozen,
  manager,
  stale,
  onDefrost,
  onHorseraceClick,
  closedAt,
  publishDate,
  presentationMeetingDate,
}) => {
  const { t } = useTranslation();
  const htmlParser = new Parser();

  const isJobClosed = Boolean(closedAt);
  const isJobInactive = frozen || isJobClosed;

  const wrapperClassNames = classNames('c-job-summary', {
    'c-job-summary--inactive': isJobInactive,
  });

  const handleDefrost = () => {
    onDefrost();
  };

  return (
    <div data-testid="job-summary-container" className={wrapperClassNames}>
      {!!Object.keys(infos).length && (
        <div className="c-job-summary__info o-antipodes">
          <div className="o-antipodes__item">
            <NavLink to={`/jobs/${infos.jobId}`}>
              <p
                data-testid="job-summary-position"
                className="c-job-summary__position"
              >
                {infos.position}
              </p>
            </NavLink>
            <p
              data-testid="job-summary-customer"
              className="c-job-summary__customer"
            >
              {infos.customer}
            </p>
          </div>
          <div className="o-antipodes__item">
            {!!manager ? (
              <div
                data-testid="job-summary-manager"
                className="c-job-summary__delegate"
              >
                <span className="delegate-text">
                  {htmlParser.parse(
                    t('all-jobs.job-header.managed_by', {
                      name: `${manager.first_name} ${manager.last_name}`,
                    }),
                  )}
                </span>

                <Avatar
                  avatar={manager.icon}
                  size={24}
                  modifier="delegate-img"
                />
              </div>
            ) : null}

            <span
              data-testid="job-summary-date"
              className="c-job-summary__date"
            >
              {htmlParser.parse(
                t('all-jobs.job-header.code', {
                  date: infos.date
                    ? formatDate(new Date(infos.date), 'dd/MM/yyyy')
                    : '',
                  code: infos.code,
                  jobId: infos.jobId,
                }),
              )}
            </span>

            <span data-testid="job-summary-fee" className="c-job-summary__fee">
              {htmlParser.parse(
                t('all-jobs.job-header.success_fee', {
                  success_fee: infos.fee,
                }),
              )}
            </span>
          </div>
        </div>
      )}

      <div
        data-testid="job-summary-completion"
        className="c-job-summary__completion"
      >
        {stale ? (
          <div
            data-testid="job-summary-completion-stale"
            className="c-job-summary__completion--stale"
          >
            {htmlParser.parse(t('all-jobs.job-header.stale_label', { stale }))}
          </div>
        ) : null}
        <JobCompletion
          uuid={uuid}
          publishDate={publishDate}
          presentationMeetingDate={presentationMeetingDate}
          statistics={statistics}
          steps={steps}
          horserace={horserace}
          // onHorseraceClick={onHorseraceClick}
        />
      </div>

      {isJobInactive ? (
        <div
          data-testid="job-summary-frozen"
          className="c-job-summary__inactive-container"
        >
          <p className="c-job-summary__inactive-title">
            {t(
              `all-jobs.job-header.${isJobClosed ? 'closed' : 'frozen.label'}`,
            )}
          </p>

          {frozen && !isJobClosed ? (
            <div className="c-job-summary__inactive-cta">
              <Action
                onClick={handleDefrost}
                label={t('all-jobs.job-header.frozen.cta')}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

JobSummary.defaultProps = {
  modifier: '',
  infos: {},
  numbers: {},
  steps: [],
  manager: null,
  stale: null,
  onDefrost: () => {},
  onHorseraceClick: () => {},
};

export default JobSummary;
