import { useEffect, useState } from 'react';
import {
  apiApplicationOfferAcceptPost,
  apiApplicationOfferUpdate,
} from '../../../api/applications';
import { useTranslation } from 'react-i18next';
import { EXTERNAL_LINKS_HOST } from '../../../.configs/environment';

export const useOfferDetails = (application, job, setAlertError) => {
  const { t } = useTranslation();
  const [offerDetailsFormData, setOfferDetailsFormData] = useState(null);
  const [isOfferDetailsFormValid, setIsOfferDetailsFormValid] = useState(true);

  const onOfferDetailsFormChange = ({ formData, isValid }) => {
    setOfferDetailsFormData(formData);
    setIsOfferDetailsFormValid(isValid);
  };

  const onOfferDetailsFormSubmit = async () => {
    if (!isOfferDetailsFormValid || !offerDetailsFormData) {
      return;
    }

    try {
      await apiApplicationOfferUpdate({
        applicationId: application.id,
        offer: offerDetailsFormData,
      });

      await apiApplicationOfferAcceptPost({ applicationId: application.id });

      window.location.assign(
        `${EXTERNAL_LINKS_HOST}/dashboard/recruitment/jobs/${job.uuid}/candidates/${application.candidateId}/success-fee`,
      );
    } catch (error) {
      if (!error.errors || !error.errors[0].message) {
        return setAlertError(t('errors.generic'));
      }

      setAlertError(t(error.errors[0].message, t('errors.generic')));
    }
  };

  useEffect(() => {
    setOfferDetailsFormData(application.offer || {});
  }, [application]);

  return {
    isOfferDetailsFormValid,
    onOfferDetailsFormChange,
    onOfferDetailsFormSubmit,
  };
};
