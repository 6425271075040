import { Alert, AlertProps } from '@reverse-hr/pattern-library';
import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ShareEmployerViewRecipientsList } from './ShareEmployerViewRecipientsList';
import { useRecipients } from './use-recipients';
import { ShareEmployerViewForm } from './ShareEmployerViewForm';
import type { TShare } from '../../models/Share';
import { ShareEmployerViewCopy } from './ShareEmployerViewCopy';
import type { TJobLegacy } from '../../models/Job';
import { ShareEmployerViewRemoveRecipient } from './ShareEmployerViewRemoveRecipient';
import classNames from 'classnames';

interface TShareEmployerViewProps {
  jobUuid: TJobLegacy['uuid'];
  onRemoveRecipientModalChange?: (isOpen: boolean) => void;
}

export const ShareEmployerView = forwardRef(
  (
    { jobUuid, onRemoveRecipientModalChange }: TShareEmployerViewProps,
    _ref,
  ) => {
    const { t } = useTranslation('', {
      keyPrefix: 'modals.shareEmployerView',
    });

    const {
      fetchGuestsRecipient,
      guestRecipients,
      mainRecipient,
      updateGuestRecipients,
    } = useRecipients(jobUuid);

    const [recipientToRemove, setRecipientToRemove] =
      useState<TShareEmployerViewRecipientToRemove | null>(null);

    const [alert, setAlert] = useState<TAlertConfig | null>(null);

    const wrapperClassNames = classNames('c-share-employer-view', {
      'c-share-employer-view--remove-recipient': recipientToRemove,
    });

    const onAlertClose = () => {
      setAlert(null);
    };

    const onCopySuccess = () => {
      setAlert({
        icon: 'icn-circle-check-outline-24px',
        message: t('copySuccessAlert'),
        variant: 'positive',
        autoCloseTime: 3000,
      });
    };

    const onError = (error: string) => {
      setAlert({
        icon: 'icn-alert-24px',
        message: error,
        variant: 'warning',
      });
    };

    const onRecipientAdded = ({
      addedRecipient,
      updatedRecipients,
    }: {
      addedRecipient: string;
      updatedRecipients: TShare[];
    }) => {
      updateGuestRecipients(updatedRecipients);

      setAlert({
        icon: 'icn-circle-check-outline-24px',
        message: t('form.addedRecipientAlert', { addedRecipient }),
        variant: 'positive',
      });
    };

    const onRemoveRecipientClick = (
      selectedRecipient: TShareEmployerViewRecipientToRemove,
    ) => {
      setRecipientToRemove(selectedRecipient);

      if (onRemoveRecipientModalChange) {
        onRemoveRecipientModalChange(true);
      }
    };

    const onRemoveRecipientModalClose = () => {
      setRecipientToRemove(null);

      if (onRemoveRecipientModalChange) {
        onRemoveRecipientModalChange(false);
      }
    };

    return (
      <div className={wrapperClassNames}>
        {recipientToRemove ? (
          <ShareEmployerViewRemoveRecipient
            jobUuid={jobUuid}
            onClose={onRemoveRecipientModalClose}
            onRecipientRemoved={fetchGuestsRecipient}
            recipient={recipientToRemove}
          />
        ) : (
          <>
            {alert && (
              <Alert
                closable={alert.autoCloseTime == null}
                onClose={onAlertClose}
                icon={alert.icon}
                type={alert.variant}
                autoCloseTime={alert.autoCloseTime}
                modifier="c-alert--fixed c-share-employer-view__alert"
              >
                {alert.message}
              </Alert>
            )}

            <h2 className="c-share-employer-view__title">{t('title')}</h2>

            <ShareEmployerViewForm
              jobUuid={jobUuid}
              onError={onError}
              onRecipientAdded={onRecipientAdded}
            />

            <ShareEmployerViewRecipientsList
              guestRecipients={guestRecipients}
              mainRecipient={mainRecipient}
              onRemoveRecipientClick={onRemoveRecipientClick}
            />

            <ShareEmployerViewCopy
              jobUuid={jobUuid}
              onCopySuccess={onCopySuccess}
            />
          </>
        )}
      </div>
    );
  },
);

export interface TShareEmployerViewRecipient {
  email: string;
  fullName: string;
  id: string;
}

export interface TShareEmployerViewRecipientToRemove {
  fullName: TShareEmployerViewRecipient['fullName'];
  shareId: string;
}

export interface TFormData {
  email: string;
  firstName: string;
  language: string;
  lastName: string;
}

interface TAlertConfig {
  icon: AlertProps['icon'];
  message: string;
  variant: AlertProps['type'];
  autoCloseTime?: AlertProps['autoCloseTime'];
}
